import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { errorMsg } from '../SnackbarUtilsConfigurator';
import { TextInputTG } from 'views/components/formElements';
import CreateNewDialog from 'common/CreateNewDialog';

interface Props {
  initialValues?: { name: string; description: string };
  onSubmit: (result: { name: string; description: string}) => void;
  onClose: () => void;
  title: string;
  submitText?: string;
}

export const ValidationMapperForm: React.FC<Props> = ({ initialValues, onSubmit, onClose, title , submitText}) => {
  const classes = useStyle();
  const [name, setName] = useState<string>(initialValues?.name || '');
  const [description, setDescription] = useState<string>(initialValues?.description || '');
  const handleOnSubmit = () => {
    if (!name) return errorMsg('Name is required');
    onSubmit({name, description});
  };

  return (
    <CreateNewDialog title={title} onClose={onClose} onSubmit={handleOnSubmit} submitText={submitText}>
      <TextInputTG
        id="name"
        label={'Name'}
        value={name || ''}
        onChange={(value) => setName(value)}
        labelRoot={classes.label}
        className={classes.input}
      />
       <TextInputTG
        id="description"
        label={'Description'}
        value={description || ''}
        onChange={(value) => setDescription(value)}
        labelRoot={classes.label}
      />
    </CreateNewDialog>
  );
};

const useStyle = makeStyles({
  label: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: '100%',
  },
  input: {
    paddingBottom: 24,
  }
});
