import { useCallback, useEffect, useState } from 'react';
import { Box, Menu, MenuItem, Typography, makeStyles, IconButton, alpha } from '@material-ui/core';
import { faMinus, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArrowDropDown } from '@material-ui/icons';
import { colors } from 'utils/colors';
import { CONFIRMATION, goToWithReplace } from '../../../../../utils/Utils';
import { useNavigate } from 'react-router-dom';
import { useConfirmDialog } from 'context/ConfirmContext';

interface VersionProp {
  type?: string;
  name: string;
  versionNos: number[];
  onDeleteVersion: (name: string, versionIndex: number, versionNumber: number) => void;
  onAddVersion: (name: string, versionIndex: number) => void;
  selectedVersionIndex: number;
  versionChangePath: string;
}

const VersionSelect = ({
  type,
  name,
  versionNos,
  onDeleteVersion,
  onAddVersion,
  selectedVersionIndex,
  versionChangePath,
}: VersionProp) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const getVersions = useCallback(() => versionNos.map((value) => `Version ${value}`), [
    versionNos,
  ]);
  const [versions, setVersions] = useState(getVersions());
  const { openConfirmation } = useConfirmDialog();

  useEffect(() => {
    setVersions(getVersions());
  }, [versionNos, getVersions]);
  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);

  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelectVersion = (e: MouseEvent, index: number) => {
    e.stopPropagation();
    handleClose();
    goToWithReplace(navigate, versionChangePath.replace('versionIndex', index.toString()));
  };

  const onHandleDelete = ({ index, version }: { index: number; version: number }) => async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.stopPropagation();
    const data = CONFIRMATION.version.delete({ version, name, type });
    const status = await openConfirmation(data);
    if (status === 'confirm') {
      onDeleteVersion(name, index, version);
      if (versionNos.length !== 1) {
        goToWithReplace(navigate, versionChangePath.replace('versionIndex', (versionNos.length-2).toString()));
      }
    }
  };
  const onHandleAdd = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    const data = CONFIRMATION.version.create({ name, type });
    const status = await openConfirmation(data);
    if (status === 'confirm') {
      onAddVersion(name, versionNos.length - 1);
      goToWithReplace(navigate, versionChangePath.replace('versionIndex', (versionNos.length).toString()));
    }
  };

  const value = versions.find((_o, index) => index === selectedVersionIndex);

  return (
    <Box className={classes.container}>
      <Box
        onClick={(event) => handleClick((event as unknown) as MouseEvent)}
        className={classes.selectButton}
      >
        <Typography className={classes.label}>{value}</Typography>
        <ArrowDropDown
          className={`${classes.dropdownArrow} ${Boolean(anchorEl) && classes.activeArrow}`}
        />
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl as Element}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
        className={classes.menuContainer}
      >
        <Box className={classes.versionItem}>
          <Typography className={`${classes.label} ${classes.title}`}>Version</Typography>
          <IconButton onClick={onHandleAdd}>
            <FontAwesomeIcon icon={faPlus} className={classes.icon} />
          </IconButton>
        </Box>
        {versions.map((version, index) => {
          return (
            <MenuItem
              key={version}
              onClick={(e) => onSelectVersion((e as unknown) as MouseEvent, index)}
              classes={{ root: classes.menuItem }}
              selected={index === selectedVersionIndex}
            >
              <Typography className={classes.label}>{version}</Typography>
              <IconButton
                onClick={onHandleDelete({ index, version: Number(version.split(' ')[1]) })}
              >
                <FontAwesomeIcon icon={faMinus} className={classes.icon} />
              </IconButton>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  selectButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 6px 5px 16px',
    cursor: 'pointer',
    width: 140,
    height: 35,
    border: `1px solid ${colors.black25}`,
    borderRadius: 5,
  },
  label: {
    fontSize: 16,
    fontWeight: 400,
    color: colors.black,
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 259,
    height: '41px !important',
    borderRadius: 5,
    margin: '0 5px 0 7px',
    padding: '12px 1px 13px 13px',
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.light, 0.2),
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.light, 0.2),
      },
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.light, 0.2),
    },
  },
  dropdownArrow: {
    color: colors.black54,
    transform: 'rotate(0deg)',
    transition: 'transform 300ms',
  },
  activeArrow: {
    transform: 'rotate(180deg)',
  },
  icon: {
    color: colors.black40,
    fontSize: 18,
  },
  menuContainer: {
    '& .MuiPaper-root': {
      width: 271,
      marginTop: 6,
      boxShadow: `0px 2px 10px 3px ${colors.black10}`,
      borderRadius: 10,
    },
  },
  versionItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 6px 0 20px',
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
  },
}));

export default VersionSelect;
