import { NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import React from 'react';
import styled from 'styled-components';
import { BadgeColors, colors } from 'utils/colors';

interface BadgeProps {
  controlDefinition: {
    required?: boolean;
    readOnly?: boolean;
    disableAutoSuggest?: boolean;
    errorMap?: NodeMapDefinition[];
    warningMap?: NodeMapDefinition[];
    conditionalMap?: NodeMapDefinition | undefined;
    computedMap?: NodeMapDefinition | undefined;
    repeats?:
      | {
          enabled?: boolean | undefined;
          min: number;
          max: number;
          addButtonText?: string | undefined;
          deleteConfirmationMessage?: string | undefined;
          allowReordering?: boolean | undefined;
          allowInitialDataDelete?: boolean | undefined;
          allowAdding?: boolean | undefined;
        }
      | undefined;
    multiplePoints?: {
      enabled?: boolean | undefined;
      maximun?: number;
      unlimited?: boolean | undefined;
    };
  };
  isGroup?: boolean;
}

const getBadgeColor: (controlType: string) => { color: string; background?: string } = (
  controlType
) => {
  switch (controlType) {
    case 'required':
      return {
        color: `${BadgeColors.salmonPink}`,
        background: `${BadgeColors.salmonPink15}`,
      };
    case 'readOnly':
      return {
        color: `${BadgeColors.skyBlue}`,
        background: `${BadgeColors.skyBlue15}`,
      };
    case 'disableAutoSuggest':
      return {
        color: `${BadgeColors.cornflowerBlue}`,
        background: `${BadgeColors.cornflowerBlue15}`,
      };
    case 'errorMap':
      return {
        color: `${BadgeColors.steelBlue}`,
        background: `${BadgeColors.steelBlue15}`,
      };
    case 'warningMap':
      return {
        color: `${BadgeColors.peach}`,
        background: `${BadgeColors.peach15}`,
      };
    case 'conditionalMap':
      return {
        color: `${BadgeColors.slateGray}`,
        background: `${BadgeColors.slateGray15}`,
      };
    case 'repeats':
      return {
        color: `${BadgeColors.sageGreen}`,
        background: `${BadgeColors.sageGreen15}`,
      };
    case 'computed':
      return {
        color: `${BadgeColors.lavenderPurple}`,
        background: `${BadgeColors.lavenderPurple15}`,
      };
    default:
      return { color: colors.gray };
  }
};

export const FormBadges: React.FC<BadgeProps> = ({ controlDefinition, isGroup }) => {
  const active =
    Boolean(controlDefinition.conditionalMap) ||
    controlDefinition.disableAutoSuggest ||
    Boolean(controlDefinition.errorMap) ||
    controlDefinition.readOnly ||
    Boolean(controlDefinition.repeats?.enabled || controlDefinition.multiplePoints?.enabled) ||
    controlDefinition.required ||
    Boolean(controlDefinition.warningMap) ||
    Boolean(controlDefinition.computedMap);
  const isGroupActive =
    isGroup &&
      Boolean(controlDefinition.repeats?.enabled || controlDefinition.multiplePoints?.enabled || controlDefinition.conditionalMap || controlDefinition.computedMap);
  return (
    <Container isBadgeActive={active} isGroup={isGroupActive}>
      {controlDefinition.required && (
        <Badge {...getBadgeColor('required')}>
          <BadgeLabel {...getBadgeColor('required')}>Required</BadgeLabel>
        </Badge>
      )}
      {controlDefinition.readOnly && (
        <Badge {...getBadgeColor('readOnly')}>
          <BadgeLabel {...getBadgeColor('readOnly')}>Read-Only</BadgeLabel>
        </Badge>
      )}
      {controlDefinition.disableAutoSuggest && (
        <Badge {...getBadgeColor('disableAutoSuggest')}>
          <BadgeLabel {...getBadgeColor('disableAutoSuggest')}>Autosuggest Off</BadgeLabel>
        </Badge>
      )}
      {Boolean(controlDefinition.errorMap) && (
        <Badge {...getBadgeColor('errorMap')}>
          <BadgeLabel {...getBadgeColor('errorMap')}>Error Mapping</BadgeLabel>
        </Badge>
      )}
      {Boolean(controlDefinition.warningMap) && (
        <Badge {...getBadgeColor('warningMap')}>
          <BadgeLabel {...getBadgeColor('warningMap')}>Warning Mapping</BadgeLabel>
        </Badge>
      )}
      {Boolean(controlDefinition.conditionalMap) && (
        <Badge {...getBadgeColor('conditionalMap')}>
          <BadgeLabel {...getBadgeColor('conditionalMap')}>Conditional Map</BadgeLabel>
        </Badge>
      )}
      {Boolean(controlDefinition.repeats?.enabled || controlDefinition.multiplePoints?.enabled) && (
        <Badge {...getBadgeColor('repeats')}>
          <BadgeLabel {...getBadgeColor('repeats')}>Repeating Instances</BadgeLabel>
        </Badge>
      )}
      {controlDefinition.computedMap && (
        <Badge {...getBadgeColor('computed')}>
          <BadgeLabel {...getBadgeColor('computed')}>Computed</BadgeLabel>
        </Badge>
      )}
    </Container>
  );
};

const Badge = styled.div<{ color: string; background?: string }>`
  padding: 3px 9px;
  border-radius: 8px;
  background: ${(props) => (props.background ? props.background : 'transparent')};
  color: ${(props) => props.color};
  height: 18px;
`;

const BadgeLabel = styled.div<{ color?: string }>`
  color: ${(props) => props.color};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
`;

const Container = styled.div<{ isBadgeActive?: boolean; isGroup?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin-inline: 18px;
  margin-top: ${(props) => (props.isBadgeActive ? `${props.isGroup ? '0px' : '13px'}` : `0px`)};
  margin-bottom: ${(props) => (props.isGroup ? `12px` : `0px`)};
`;
