import React, { useState, useCallback, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CheckboxInput } from '../FormElements';
import { NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import {
  V2TextTemplateComponent,
  V2NumberTemplateComponent,
  V2BarcodeComponent,
  V2LocationComponent,
  V2PolylineComponent,
} from '@terragotech/form-renderer';
import { JSONSchema6, JSONSchema6Definition } from 'json-schema';
import _ from 'lodash';
import { LocalSchemaDefinition } from '../../utils/useSchemaLookup';
import { useFormSchemas } from './hooks/useFormSchemas';
import { DUPLICATE_NAME_ERROR_MESSAGE } from '../../pages/aggregates/utils/formUtils';
import RepeatableComponent, { defaultRepeatableProps } from '../RepeatableComponent';
import MultiplePoints from '../MultiplePoints';
import { getPropertyObjectFromFormComponent } from '../../pages/aggregates/utils/V2FormTemplateToJsonSchema';
import { useStyles } from './useStyles';
import { FormEditProps } from 'utils/types';
import MapperItem from './MapperItem';
import FormValidation from './FormValidation';
import { Mapper } from './MapperDefinition';
import { TextInput } from './common';
import { useConfirmDialog } from 'context/ConfirmContext';
import { CONFIRMATION, expandPath } from 'utils/Utils';

export type TextTemplateWithName =
  | (V2TextTemplateComponent & {
      name: string;
      droppableId: string;
      index?: number;
    })
  | (V2NumberTemplateComponent & {
      name: string;
      droppableId: string;
      index?: number;
    })
  | (V2BarcodeComponent & {
      name: string;
      droppableId: string;
      index?: number;
    })
  | (V2LocationComponent & {
      name: string;
      droppableId: string;
      index?: number;
    })
  | (V2PolylineComponent & {
      name: string;
      droppableId: string;
      index?: number;
    });

interface TextInputEditFormProps extends FormEditProps {
  component: TextTemplateWithName;
  autosuggest?: boolean;
  computed?: boolean;
  isImportCommand?: boolean;
}

export const TextInputEditForm: React.FC<TextInputEditFormProps> = ({
  existingNameError,
  emptyNameError,
  handleNameChange,
  emptyLabelError,
  handleLabelChange,
  component,
  autosuggest,
  computed,
  isImportCommand,
  getEditedDef,
  setFormDefinition,
}) => {
  const classes = useStyles();
  const [oldName, setOldName] = useState(component.name);
  const [name, setName] = useState(component.name);
  const [label, setLabel] = useState(component.label);
  const [required, setRequired] = useState(component.required);
  const [placeholder, setPlaceholder] = useState(component.placeholder);
  const [description, setDescription] = useState(component.description || '');
  const [info, setInfo] = useState(component.info || '');
  const [droppableId, setDroppableId] = useState(component.droppableId);
  const [type, setType] = useState(component.type);

  const [readOnly, setReadOnly] = useState(computed || component.readOnly);
  const [disableAutoSuggest, setDisableAutoSuggest] = useState(
    (component as V2TextTemplateComponent).disableAutoSuggest
  );
  const [repeats, setRepeats] = useState(
    component.repeats ? { ...defaultRepeatableProps, ...component.repeats } : defaultRepeatableProps
  );
  const [multiplePoints, setMultiplePoints] = useState(
    (component as V2LocationComponent).multiplePoints || undefined
  );
  const [errorMap, setErrorMap] = useState<NodeMapDefinition[] | undefined>(
    component.errorMap || undefined
  );
  const [warningMap, setWarningMap] = useState<NodeMapDefinition[] | undefined>(
    component.warningMap || undefined
  );
  const [conditionalMap, setConditionalMap] = useState(component.conditionalMap || undefined);
  const [conditionalOpen, setConditionalOpen] = useState(false);
  const [computedMap, setComputedMap] = useState(() => {
    const map = (component as V2TextTemplateComponent).computedMap || undefined;
    if (map && map.nodes && map.nodes['OUTPUT']) {
      map.nodes['OUTPUT'].config = { objectSchema: 'COMPUTED' };
    }
    return map;
  });
  const [computedOpen, setComputedOpen] = useState(false);

  const formSchemas = useFormSchemas();
  const { openConfirmation } = useConfirmDialog();

  const setInitialData = useCallback(() => {
    setName(component.name || '');
    setOldName(component.name || '');
    setLabel(component.label || '');
    setType(component.type);
    setDroppableId(component.droppableId);
    setRequired(component.required);
    setPlaceholder(component.placeholder || '');
    setDescription(component.description || '');
    setInfo(component.info || '');
    setReadOnly(computed || component.readOnly);
    setDisableAutoSuggest((component as V2TextTemplateComponent).disableAutoSuggest);
    setRepeats(
      component.repeats
        ? { ...defaultRepeatableProps, ...component.repeats }
        : defaultRepeatableProps
    );
    setMultiplePoints((component as V2LocationComponent).multiplePoints || undefined);
    setErrorMap(component.errorMap || undefined);
    setWarningMap(component.warningMap || undefined);
    setConditionalMap(component.conditionalMap || undefined);
    setComputedMap(() => {
      const map = (component as V2TextTemplateComponent).computedMap || undefined;
      if (map && map.nodes && map.nodes['OUTPUT']) {
        map.nodes['OUTPUT'].config = { objectSchema: 'COMPUTED' };
      }
      return map;
    });
  }, [component, computed]);

  useEffect(() => {
    setInitialData();
  }, [setInitialData]);

  const getFormValues = useCallback(() => {
    return {
      type: type,
      name,
      label,
      ...(placeholder && { placeholder }),
      ...(required !== undefined && { required }),
      ...(description && { description }),
      ...(info && { info }),
      ...(readOnly !== undefined && { readOnly }),
      ...(disableAutoSuggest !== undefined && { disableAutoSuggest }),
      repeats,
      multiplePoints,
      ...(conditionalMap && { conditionalMap }),
      ...(computedMap && { computedMap }),
      ...(errorMap && { errorMap }),
      ...(warningMap && { warningMap }),
      droppableId: droppableId,
    };
  }, [
    droppableId,
    type,
    name,
    label,
    placeholder,
    required,
    description,
    info,
    readOnly,
    disableAutoSuggest,
    repeats,
    multiplePoints,
    conditionalMap,
    computedMap,
    errorMap,
    warningMap,
  ]);
  useEffect(() => {
    if (getEditedDef && setFormDefinition && name.length > 0 && !existingNameError && !emptyNameError && !emptyLabelError) {
      const editedDef = getEditedDef({
        editedData: getFormValues(),
        oldName,
        newName: name,
        droppableId: component.droppableId,
      });
      if (editedDef) {
        setFormDefinition(editedDef);
        if (oldName !== name) {
          setOldName(name);
        }
      }
    }
    // If add those all dependency, it'll call recursively
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFormValues, name]);

  const parts = (component.droppableId || '').split('.');
  const t =
    !component.droppableId || component.droppableId === 'form'
      ? []
      : expandPath(parts, formSchemas.FORM.schema.properties as JSONSchema6);

  let formValue = { value: getPropertyObjectFromFormComponent(component) };
  for (let i = 0; i < t.length; i++) {
    formValue = { ...formValue, ...t[i] };
  }

  const groupSchema = formSchemas.FORM.schema?.properties?.[component.droppableId] as JSONSchema6;

  const errorWarningSchemas: LocalSchemaDefinition = {
    FORMVALUE: {
      schema: {
        type: 'object',
        properties: formValue,
      } as JSONSchema6,
      schemaLabel: 'Current Field Value',
    },
    ...formSchemas,
  };

  const onConfirm = () => openConfirmation(CONFIRMATION.commonClear);
  const handleClearConditionalMapper = async () => {
    const status = await onConfirm();
    if (status === 'confirm') {
      setConditionalMap(undefined);
    }
  };
  const handleClearComputedMapper = async () => {
    const status = await onConfirm();
    if (status === 'confirm') {
      setComputedMap(undefined);
    }
  };

  const doesConditionalHaveValue = useCallback(() => {
    return !_.isEmpty(conditionalMap);
  }, [conditionalMap]);

  const doesComputedHaveValue = useCallback(() => {
    return !_.isEmpty(computedMap);
  }, [computedMap]);

  const mapperList: Mapper[] = [
    {
      title: 'Error Mapping',
      data: errorMap || [],
      localSchemas: errorWarningSchemas,
      setData: (data) => setErrorMap(data),
      mapScenario: 'FIELD_LEVEL_ERROR',
      containerStyle: classes.firstMapper,
    },
    {
      title: 'Warning Mapping',
      data: warningMap || [],
      localSchemas: errorWarningSchemas,
      setData: (data) => setWarningMap(data),
      mapScenario: 'FIELD_LEVEL_WARNING',
      containerStyle: classes.secondMapper,
    },
  ];

  return (
    <>
      <Typography className={classes.header}>{name}</Typography>
      <TextInput
        autoFocus
        label="Name"
        error={existingNameError || emptyNameError}
        helperText={existingNameError ? DUPLICATE_NAME_ERROR_MESSAGE : (emptyNameError ? 'Name cannot be empty' : '')}
        value={name}
        onChange={(value) => handleNameChange && handleNameChange(value || '', setName)}
        placeholder="Enter name"
      />
      <TextInput
        error={emptyLabelError}
        helperText={emptyLabelError ? 'Label cannot be empty' : ''}
        label="Label"
        value={label}
        onChange={(value) => handleLabelChange && handleLabelChange(value || '', setLabel)}
        placeholder="Enter label"
      />
      <TextInput
        label="Placeholder"
        value={placeholder}
        onChange={(value) => setPlaceholder(value)}
        placeholder="Enter placeholder"
      />
      <TextInput
        label="Description"
        value={description}
        onChange={(value) => setDescription(value)}
        placeholder="Enter description"
      />
      <TextInput
        label="Info"
        value={info}
        onChange={(value) => setInfo(value)}
        className={classes.bottom30}
        placeholder="Enter info"
      />
      <Box className={`${classes.itemContainer} ${classes.bottom20}`}>
        <Typography className={classes.label}>Additional Options</Typography>
        <Box className={classes.checkBoxContainer}>
          <CheckboxInput
            title="Required"
            checked={required}
            onChange={(value) => setRequired(value)}
          />
          {!computed && (
            <CheckboxInput
              title="Read-only"
              checked={readOnly}
              onChange={(value) => setReadOnly(value)}
            />
          )}
          {autosuggest && (
            <CheckboxInput
              title="Disable Autosuggest"
              checked={disableAutoSuggest}
              onChange={(value) => setDisableAutoSuggest(value)}
            />
          )}
        </Box>
      </Box>
      <MapperItem
        {...{
          onToggleMapper: setConditionalOpen,
          isActive: doesConditionalHaveValue(),
          clearMapper: handleClearConditionalMapper,
          openDataMap: conditionalOpen,
          dataMap: conditionalMap,
          setDataMap: setConditionalMap,
          localSchemaDefinition: errorWarningSchemas,
          title: 'Conditional Map',
          mapScenario: 'FORM_FIELD_CONDITIONAL',
        }}
      />
      {computed && (
        <MapperItem
          {...{
            onToggleMapper: setComputedOpen,
            isActive: doesComputedHaveValue(),
            clearMapper: handleClearComputedMapper,
            openDataMap: computedOpen,
            dataMap: computedMap,
            setDataMap: setComputedMap,
            localSchemaDefinition: {
              ...errorWarningSchemas,
              COMPUTED: {
                schemaLabel: 'Result',
                schema: {
                  type: 'object',
                  properties: {
                    result: ((groupSchema?.items as JSONSchema6)?.properties ||
                      groupSchema?.properties ||
                      formSchemas.FORM.schema?.properties ||
                      {})[component.name] as JSONSchema6Definition,
                  },
                },
              },
            },
            title: 'Calculation',
            mapScenario: 'COMPUTED_MAPPING',
          }}
        />
      )}

      {(!isImportCommand || component.type === 'location') && (
        <Box className={`${classes.itemContainer} ${classes.bottom20}`}>
          <Typography className={classes.label}>Repeating Instances</Typography>
          {!isImportCommand && (
            <RepeatableComponent
              repeats={repeats}
              setRepeats={setRepeats}
              disabledSwitch={multiplePoints?.enabled}
            />
          )}

          {component.type === 'location' && (
            <MultiplePoints
              multiplePoints={multiplePoints}
              setMultiplePoints={setMultiplePoints}
              disabledSwitch={repeats?.enabled}
            />
          )}
        </Box>
      )}
      <FormValidation mapperList={mapperList} />
    </>
  );
};
