import {
  faDatabase,
  faGearComplexCode,
  faPenRuler,
  faCircleQuestion,
} from '@fortawesome/pro-light-svg-icons';
import { CommandDefinition, EventDefinition } from '@terragotech/gen5-config-lib';
import { AggrNodeRow } from '../context/fakeAPIHooks/useAggregateAPI';
import { MouseEvent } from 'react';
import { metadataSchema } from './jsonPartsGenerators';
import { convertV2FormTemplateToJsonSchema } from '../pages/aggregates/utils/V2FormTemplateToJsonSchema';
import { convertV2FormTemplateToCommandDataSchema } from '../pages/aggregates/utils/V2FormTemplateToCommandDataSchema';
import { V2FormTemplate } from '@terragotech/form-renderer';
import { LocationProperty, PropertyCollection } from '@terragotech/gen5-config-lib';
import { commands } from '@uiw/react-md-editor';
import { categoryColors, colors } from './colors';
import { JSONSchema6, JSONSchema6Definition } from 'json-schema';
import { NavigateFunction } from 'react-router-dom';
import { UIConfigType } from './types';
import { get, hasIn } from 'lodash';
import { V2ChartComponent } from '@terragotech/page-renderer';

export const RECORDS_ROUTE = '/records';
export const UI_ROUTE = '/ui';
export const SHARED_ROUTE = '/sharedUtilities';
export const ROOT_RECORDS_ROUTE = `${RECORDS_ROUTE}/`;
export const ROOT_UI_ROUTE = `${UI_ROUTE}/`;

export const UI_MENU_SETTINGS = 'settings';
export const UI_MENU_RECORDS = 'recordsUI';
export const UI_MENU_ACTIONS = 'actions';
export const UI_MENU_CUSTOM_PAGES = 'customPages';

export const USERGUIDE_ROUTE = '/userGuide';

export const MOBILE_ONLY_CARD_DEFINITION_KEYS = ['secondaryButtonOptions', 'cardHeight'];
export const CARD_DEFINITION_KEY = 'cardDefinition';
export const LEFT_MENU_WIDTH = 260;
export const RECORD_PAGE_HEADER_HEIGHT = 72;
export const HEADER_HEIGHT = 80;
export const COMMAND_LEFT_PANEL = 390;
export const UIPAGE_HEADER_HEIGHT = 45;
export const AGGREGATE_MAP_MODAL_WIDTH = 891;
export const EVENT_HEADER_HEIGHT = 100;
export const EVENT_TAB_HEIGHT = 65;
export const ONE_TO_MANY_RELATION = 'ONE-TO-MANY';
export const FORM_SAME_NAME_ERROR_MESSAGE =
  'An element with the same name already exists in the form. Rename it before selecting another element.';
export const FORM_REQUIRED_NAME_ERROR_MESSAGE =
  'Name is a required field. Fill it out before selecting another element.';
export const FORM_REQUIRED_LABEL_ERROR_MESSAGE =
  'Label is a required field. Fill it out before selecting another element.';
export const RECORD_SEARCH_BAR_HEIGHT = 37;

export const PROPERTIES_FILTER_TITLE = 'Column Visibility';
export const actions = {
  EDITOR_ACTIONS: 'editorActions',
  TABLE_ACTIONS: 'tableActions',
};
export const SYMBOL_LEGEND_KEY = 'symbolLegend';
export const EVENT_CONFIGURATION_TABS = ['Event Schema', 'Mapping'];
export const COMMAND_TYPE_OPTIONS = [
  { key: 'BUTTON', value: 'Button' },
  { key: 'FORM', value: 'Form' },
  { key: 'IMPORT', value: 'Import' },
  { key: 'TIMER', value: 'Timer' },
];
export const CELL_STYLE = { fontSize: 15, color: colors.black, fontWeight: 400 };
export const ADD_MODAL_WIDTH = 637;
export const ASSET_SUBMENU = [
  {
    label: 'Settings',
    path: '',
  },
  {
    label: 'Properties',
    path: 'properties',
    showCount: true,
  },
  {
    label: 'Commands',
    path: 'commands',
    showCount: true,
  },
  {
    label: 'Events',
    path: 'events',
    showCount: true,
  },
  {
    label: 'Computed Mapping',
    path: 'computedMapping',
  },
];
export const LEFT_MENU_UI_NODE = [
  'cardDefinition',
  'mapLabelProperties',
  'editorWorkflows',
  'editorActions',
  'multiSelectActions',
];
export const MAIN_MENU = [
  {
    icon: faDatabase,
    label: 'Record Types',
    path: RECORDS_ROUTE,
  },
  {
    icon: faPenRuler,
    label: 'UI',
    path: UI_ROUTE,
  },
  {
    icon: faGearComplexCode,
    label: 'Shared Utilities',
    path: SHARED_ROUTE,
  },
];

export const MENU_BUTTONS = [
  {
    icon: faCircleQuestion,
    label: 'User Guide',
    path: USERGUIDE_ROUTE,
    key: 'help',
  },
];

export const UI_MENU = [
  {
    label: 'Settings',
    key: UI_MENU_SETTINGS,
    path: `${ROOT_UI_ROUTE}${UI_MENU_SETTINGS}`,
  },
  {
    label: 'Records UI',
    key: UI_MENU_RECORDS,
    path: `${ROOT_UI_ROUTE}${UI_MENU_RECORDS}`,
  },
  {
    label: 'Actions',
    key: UI_MENU_ACTIONS,
    path: `${ROOT_UI_ROUTE}${UI_MENU_ACTIONS}`,
  },
  {
    label: 'Custom Pages',
    key: UI_MENU_CUSTOM_PAGES,
    path: `${ROOT_UI_ROUTE}${UI_MENU_CUSTOM_PAGES}`,
  },
];

const UI_SETTINGS = `${ROOT_UI_ROUTE}settings/`;
export const UI_SETTINGS_LEFT_MENU = [
  {
    label: 'General',
    keyValue: 'general',
    path: `${UI_SETTINGS}general`,
    isLink: false,
  },
  {
    label: 'Symbol Legend',
    keyValue: 'symbolLegend',
    path: `${UI_SETTINGS}symbolLegend`,
    isLink: true,
  },
  {
    label: 'Map Options',
    keyValue: 'mapOptions',
    path: `${UI_SETTINGS}mapOptions`,
    isLink: false,
  },
];
const UI_ACTIONS = `${ROOT_UI_ROUTE}actions/`;
export const UI_ACTIONS_LEFT_MENU = [
  {
    label: 'FAB Actions',
    title: 'FAB Actions',
    keyValue: 'fabActions',
    path: `${UI_ACTIONS}fabActions`,
    isLink: true,
  },
  {
    label: 'Import Actions (Web Only)',
    title: 'Import Actions',
    keyValue: 'importActions',
    path: `${UI_ACTIONS}importActions`,
    isLink: false,
  },
  {
    label: 'Schedule',
    title: 'Scheduled Timers',
    keyValue: 'schedule',
    path: `${UI_ACTIONS}schedule`,
    isLink: false,
  },
];

export const sharedUtilitiesTabs = [
  {
    label: 'Internal Text',
    key: 'internalText',
  },
  {
    label: 'Input Schema',
    key: 'inputSchema',
  },
  {
    label: 'Output Schema',
    key: 'outputSchema',
  },
  {
    label: 'Function Map',
    key: 'functionMap',
  },
];
export const column = [
  { key: 'Action', label: 'Action', type: 'text' },
  { key: 'names', label: 'Name', type: 'text' },
  { key: 'label', label: 'Label', type: 'text' },
  {
    key: 'type',
    label: 'Type',
    type: 'select',
    childern: [
      {
        label: 'Number',
      },
      {
        label: 'String',
      },
      {
        label: 'Id',
      },
    ],
  },
  { key: 'editable', label: 'Editable', value: true, type: 'text' },
  { key: 'nullable', label: 'Nullable', value: true, type: 'text' },
  { key: 'filterable', label: 'Filterable', value: true, type: 'text' },
  { key: 'relation', label: 'Relation', type: 'select' },
  { key: 'foreignColumns', label: 'Foreign Columns', type: 'select' },
];

export const mainTabs = [
  {
    label: 'General',
  },
  {
    label: 'Permission',
  },
];
export const userguideLeftHeader: Array<{
  title: string;
  id: keyof typeof userguideSubHeadings;
}> = [
  {
    title: 'Shared Utilities',
    id: 'shared_utilities',
  },
  {
    title: 'Custom Pages',
    id: 'customPages',
  },
];
export const userguideSubHeadings: {
  shared_utilities: Array<{
    title: string;
    id: string;
  }>;
  customPages: Array<{
    title: string;
    id: string;
  }>;
} = {
  shared_utilities: [
    {
      title: 'Array Filter',
      id: 'filter',
    },
    {
      title: 'Array Reduce',
      id: 'reduce',
    },
    {
      title: 'Array Transform',
      id: 'transform',
    },
    {
      title: 'Requirement',
      id: 'requirement',
    },
  ],
  customPages: [
    {
      title: 'Requirement',
      id: 'custom_requirement',
    },
    {
      title: 'Charts',
      id: 'custom_page_charts',
    },
  ],
};

interface UserGuideChart {
  type: string;
  description: string;
  example: string;
  steps: Array<{
    name: string;
    description: string;
  }>;
}
export const userguideCharts: Record<V2ChartComponent['chartType'], UserGuideChart> = {
  bar: {
    type: 'Bar Graph',
    description: 'Compare series of numeric data across groups.',
    example:
      'Statistical analysis of a set of records, such as count of records or average size, separated by project.',
    steps: [
      {
        name: 'Source Data',
        description: `
          Add an "Advanced Search" node (or another object array-output node), and design the data set. The resulting data set should
          include: one string or number (S|N) property as the unique ID for partitioning data, and one or more number (N) properties.
        `,
      },
      {
        name: 'Transform Data',
        description: `
          Add an "Array Transform" node, and select the "updateProps" function. Map the "Advanced Search" output to the "Items to
          Transform array" input. In the "data array" section, create multiple "data array" items: one for the unique identifier,
          and one for each of the number properties. In the "from" field for each, enter the property name shown in the "Aggregate
          Object Array"; then, in the "to" field for each, enter the label for each to be shown on the chart.
        `,
      },
      {
        name: 'Map Chart Data',
        description: `
          Map the "Transformed Items array" to the chart's "data array" on the "Object Builder" or "Form State" node; then, enter the
          unique identifier label in the "indexBy" field, and the number property labels in the "keys array". Ensure that each field
          matches the corresponding label in the "to" fields.
        `,
      },
    ],
  },
  line: {
    type: 'Line Graph',
    description: `
      Compare cartesian data across a series of groups, representing trends across each group. Each series is represented by a colored
      line connecting points within each group.
    `,
    example:
      'Statistical analysis of records, such as count of records, or changes in record status, over a period of time.',
    steps: [
      {
        name: 'Source Data',
        description: `
          Add an "Advanced Search" node (or another object array-output node), and design the data set. The resulting data set should
          include: two number, string, or Date (N|S|D) properties as the cartesian data for a series.
        `,
      },
      {
        name: 'Convert Dates',
        description: `
          If some of the search data are dates, add an "Array Transform" node, and select the "parseDateProps" function. Map the
          "Advanced Search" output to the "Items to Transform array" input. In the "properties array" section, create an item for
          each date property. In the "key" field for each, enter the property name as shown in the "Aggregate Object Array [0] object"
          for each date property. Optionally, to convert to a date without timestamp, map the "Exists?" output from the "Advanced
          Search" node (or another boolean-output node) to the "dateOnly" field; this can be applied per key next to the "key" field,
          or for all specified fields by mapping to the "dateOnly (all keys)" field. Note that this will not retroactively apply to
          groupings in the search; to group by date, create a new property with a Computed Mapping on the Record Type.
        `,
      },
      {
        name: 'Transform Data',
        description: `
          Add an "Array Transform" node, and select the "updateProps" function. Map the "Advanced Search" output (or the previous
          "Array Transform" node for dates) to the "Items to Transform array" input. In the "data array" section, create two "data
          array" items: one for each of two cartesian data properties. In the "from" field for each, enter the property name shown
          in the "Aggregate Object Array [0] object"; then, in the "to" field for each, enter either lowercase "x" or "y" for each
          axis.
        `,
      },
      {
        name: 'Map Chart Data',
        description: `
          Map the "Transformed Items array" to the chart's first "serieData array" inside the "data array" on the "Object Builder"
          or "Form State" node. Add a "Trim Text" node (or another string-output node), enter a label for the data series, and map
          the string output to the "id" field next to the "serieData array". Repeat this process for additional series, mapping to
          additional "data" objects in the "data array".
        `,
      },
    ],
  },
  pie: {
    type: 'Pie Chart',
    description:
      'Compare a single numeric data point across a group, as a proportion of the full data set.',
    example:
      'Statistical count of records in each project, or cumulative utilization for individual records.',
    steps: [
      {
        name: 'Source Data',
        description: `
          Add an "Advanced Search" node (or another object array-output node), and design the data set. The resulting data set should
          include: one string or number (S|N) property as the unique ID, and one number (N) property as the value.
        `,
      },
      {
        name: 'Map Chart Data',
        description: `
          Map the "Aggregate Object Array [0] object" to the chart's "data array" on the "Object Builder" or "Form State" node; then,
          enter the ID property in the "idKey" field, and the value property in the "valueKey" field. Ensure that each field matches
          the corresponding property names from "Aggregate Object Array [0] object".
        `,
      },
    ],
  },
  scatterPlot: {
    type: 'Scatter Plot',
    description: `
      Compare cartesian data across a series of groups, representing trends across the data. Each series is represented by a set of
      colored points overlapping points in other series.
    `,
    example:
      'Correlation between size and weight, or clustering of records by geospatial coordinates, across multiple projects.',
    steps: [
      {
        name: 'Source Data',
        description: `
          Add an "Advanced Search" node (or another object array-output node), and design the data set. The resulting data set should
          include: two number, string, or Date (N|S|D) properties as the cartesian data for a series.
        `,
      },
      {
        name: 'Convert Dates',
        description: `
          If some of the search data are dates, add an "Array Transform" node, and select the "parseDateProps" function. Map the
          "Advanced Search" output to the "Items to Transform array" input. In the "properties array" section, create an item for
          each date property. In the "key" field for each, enter the property name as shown in the "Aggregate Object Array [0] object"
          for each date property. Optionally, to convert to a date without timestamp, map the "Exists?" output from the "Advanced
          Search" node (or another boolean-output node) to the "dateOnly" field; this can be applied per key next to the "key" field,
          or for all specified fields by mapping to the "dateOnly (all keys)" field. Note that this will not retroactively apply to
          groupings in the search; to group by date, create a new property with a Computed Mapping on the Record Type.
        `,
      },
      {
        name: 'Transform Data',
        description: `
          Add an "Array Transform" node, and select the "updateProps" function. Map the "Advanced Search" output (or the previous
          "Array Transform" node for dates) to the "Items to Transform array" input. In the "data array" section, create two "data
          array" items: one for each of two cartesian data properties. In the "from" field for each, enter the property name shown
          in the "Aggregate Object Array [0] object"; then, in the "to" field for each, enter either lowercase "x" or "y" for each
          axis.
        `,
      },
      {
        name: 'Map Chart Data',
        description: `
          Map the "Transformed Items array" to the chart's first "serieData array" inside the "data array" on the "Object Builder"
          or "Form State" node. Add a "Trim Text" node (or another string-output node), enter a label for the data series, and map
          the string output to the "id" field next to the "serieData array". Repeat this process for additional series, mapping to
          additional "data" objects in the "data array".
        `,
      },
    ],
  },
};

export const SYMBOL_OPTION = { globalScaleFactor: 1, embedFont: true };

export const getUrlFirstParam = (path: string, ignorePath: string) => {
  const requiredPath = path.replace(ignorePath, '');
  const slashPos = requiredPath.indexOf('/');
  return requiredPath.substr(0, slashPos === -1 ? requiredPath.length : slashPos);
};

export const getPropertiesArray = (properties?: PropertyCollection, type?: string) => {
  if (type && properties) {
    return Object.entries(properties)
      .filter(([_, value]) => {
        return value.type === type;
      })
      .map((item) => item[0]);
  }
  return properties ? Object.keys(properties) : [];
};

export const getPrimaryLocationType = (
  primaryLocationProperty: LocationProperty | string | undefined
) =>
  typeof primaryLocationProperty === 'object' && 'latitude' in primaryLocationProperty
    ? 'LATLON'
    : 'GEOGRAPHY';

export const getReOrderedItems = (
  sourceIndex: number,
  destinationIndex: number,
  definition?: { [name: string]: CommandDefinition | EventDefinition }
) => {
  const array = Object.entries(definition || {}).map(([key, value], index) => {
    return { key: key, order: index + 1, ...value };
  });
  const [movedItem] = array.splice(sourceIndex, 1);
  array.splice(destinationIndex, 0, movedItem);
  const reOrderedItems = {} as AggrNodeRow;
  array.forEach((item) => {
    const { key, order, ...other } = item;
    reOrderedItems[key] = other;
  });
  return reOrderedItems;
};

export const goTo = (navigate: NavigateFunction, path: string) => {
  navigate(path);
};

export const goToWithReplace = (navigate: NavigateFunction, path: string) => {
  navigate(path, { replace: true });
};

export const goBack = (event: MouseEvent, navigate: NavigateFunction) => {
  event.preventDefault();
  navigate(-1);
};

export const getLocalSchema = (stateSchema: JSONSchema6, formSchema: JSONSchema6) => {
  return {
    STATE: { schema: stateSchema, schemaLabel: 'Properties' },
    FORM: { schema: formSchema, schemaLabel: 'Form State' },
    METADATA: {
      schema: metadataSchema,
      schemaLabel: 'Metadata',
    },
  };
};
type ConfigType = {
  type: string;
  config: UIConfigType;
};
export const configType: Record<string, ConfigType> = {
  MOBILE: { type: 'mobile', config: 'mobileUIConfig' },
  WEB: { type: 'web', config: 'webUIConfig' },
  ALL: { type: 'all', config: 'webUIConfig' },
};
export const getLocalSchemaDefinitions = (formDefinition: V2FormTemplate) => {
  return {
    METADATA: {
      schema: metadataSchema,
      schemaLabel: 'Metadata',
    },
    STATE: {
      schema: metadataSchema,
      schemaLabel: 'Metadata',
    },
    DATA: {
      schema: convertV2FormTemplateToJsonSchema(formDefinition),
      schemaLabel: 'Form Definition',
    },
    COMMAND_DATA: {
      schema: convertV2FormTemplateToCommandDataSchema(formDefinition),
      schemaLabel: 'Command Data',
    },
  };
};
export const COMMANDS = [
  commands.bold,
  commands.italic,
  commands.strikethrough,
  commands.hr,
  commands.title,
  commands.divider,
  commands.quote,
  commands.code,
  commands.unorderedListCommand,
  commands.orderedListCommand,
  commands.checkedListCommand,
  commands.codeEdit,
  commands.codeLive,
  commands.fullscreen,
];

export const getCategoryColor = (Value: string): string => {
  let color = categoryColors.defaultColor;
  switch (Value) {
    case 'Comparison':
      color = categoryColors.comparision;
      break;
    case 'Collection':
      color = categoryColors.collection;
      break;
    case 'Conversion':
      color = categoryColors.conversion;
      break;
    case 'Date/Time':
      color = categoryColors.dateOrTime;
      break;
    case 'Geography':
      color = categoryColors.geography;
      break;
    case 'Input Data':
      color = categoryColors.inputData;
      break;
    case 'Logic':
      color = categoryColors.logic;
      break;
    case 'Data Lookup':
      color = categoryColors.dataLookup;
      break;
    case 'Math':
      color = categoryColors.math;
      break;
    case 'Text':
      color = categoryColors.text;
      break;
    case 'Photo':
      color = categoryColors.photo;
      break;
  }
  return color;
};
export const truncateString = (str: string, length: number) => {
  var dots = str.length > length ? '...' : '';
  return str.substring(0, length) + dots;
};

export const delay = (value: number) => new Promise((r) => setTimeout(r, value));

export const CONFIRMATION = {
  linking: {
    link: {
      title: 'Link Web & Mobile',
      description:
        'When Web & Mobile are linked, they will share the same configuration, and the web configuration will be applied to the mobile configuration. Are you sure you would like to link the configurations?',
      confirmationText: 'Link',
    },
    unlink: {
      title: 'Unlink Web & Mobile',
      description:
        'When Web & Mobile are unlinked, they have the ability to have different configurations. Are you sure you would like to unlink the configurations?',
      confirmationText: 'Unlink',
    },
  },
  header: {
    new: {
      description: 'The currently loaded config will be overwritten.',
      confirmationText: 'Create New',
    },
    import: {
      title: 'Import',
      description: 'Would you like to upload a file or get the current active config?',
      confirmationText: 'Current Config',
      cancellationText: 'Upload',
    },
  },
  properties: ({ label }: { label: string }) => ({
    description: `Are you sure you would like to delete the ${label} item?`,
  }),
  foreignRelation: ({property, recordType, action}: {property: string, recordType: string, action: string}) => ({
    title: `Cannot ${action}: Foreign Relationship`,
    description: `This property has a foreign relationship with the "${property}" property of the "${recordType}" record type. You must remove the relationship before you can ${action.toLowerCase()} this property.`,
    confirmationText: 'View Foreign Relationship',
  }),
  mapperDiagram: {
    description:
      'There are required nodes missing from this map. It may not work properly if the missing nodes are not created.',
    confirmationText: 'Create Nodes',
  },
  simulate: {
    title: 'Confirmation',
    description: 'You must save before simulating. Save now?',
    confirmationText: 'Save',
  },
  remapping: ({
    commandName,
    commandVersion,
  }: {
    commandName: string;
    commandVersion: number;
  }) => ({
    title: 'References detected',
    description: `The command "${commandName}" version ${commandVersion} has references. Do you want to remap them to the last version?`,
    confirmationText: 'Remap',
  }),
  saveCommand: {
    description:
      'Changing the command type will remove any configuration specific to the previous command type.',
    confirmationText: 'Save',
  },
  command: ({ name }: { name: string }) => ({
    description: `The "${name}" command will be permanently deleted.`,
  }),
  event: ({ name, aggrName }: { name: string; aggrName: string }) => ({
    description: `The event "${name}" of the "${aggrName}" aggregate will be permanently deleted.`,
  }),
  commandForm: ({ name }: { name: string }) => ({
    description: `Do you want to delete the "${name}" form element?`,
  }),
  pageLayout: ({ name }: { name: string }) => ({
    description: `Do you want to delete the "${name}" page element?`,
  }),
  customPage: ({ name }: { name: string }) => ({
    description: `The "${name}" custom page will be permanently deleted.`,
  }),
  customPage: {
    description: 'The selected custom page will be permanently deleted.',
  },
  record: ({ name }: { name: string }) => ({
    description: `The ${name} record, along with any associated properties, events, commands, record UI customizations, fab actions, imports, scheduled timers, and custom pages, will be permanently deleted.`,
  }),
  commandType: ({ action }: { action: string }) => ({
    title: `Are you sure you want to make this an ${
      action === 'import' ? 'import command' : 'integration import'
    }?`,
    description: 'This will clear all form information and cannot be undone.',
    confirmationText: 'Confirm',
  }),
  version: {
    create: ({ name, type }: { name: string; type?: string }) => ({
      description: `Do you want to create a new version of the "${name}" ${type || 'command'}?`,
      confirmationText: 'Create',
    }),
    delete: ({ version, name, type }: { version: number; type?: string; name: string }) => ({
      description: `Version "${version}" of the ${
        type || 'command'
      } "${name}" will be permanently deleted.`,
    }),
  },
  symbolLegend: ({ name }: { name: string }) => ({
    description: `The ${name} symbol will be removed.`,
  }),
  functionList: {
    description: 'All versions of the selected function will be permanently deleted.',
  },
  recordsUI: ({ name }: { name: string }) => ({
    description: `The ${name} aggregate UI customisation will be permanently deleted along with the properties, events, and commands belonging to it.`,
  }),
  mapperList: { description: 'The selected mapping will be deleted' },
  mapperRemove: {
    description: 'This node will be removed along with its mapping',
  },
  commonDelete: ({ name }: { name: string }) => ({
    description: `Are you sure you would like to delete the ${name}?`,
  }),
  commonCancel: {
    title: 'Are you sure you want to cancel these changes?',
    description: 'This cannot be undone.',
    confirmationText: 'Confirm',
  },
  commonClear: {
    confirmationText: 'Clear',
  },
  formEditor: {
    aggregateTypeChange: {
      description: 'Changing the aggregate type requires the removal of current filters.',
      confirmationText: 'Change',
    },
  },
  diableMapOptions: {
    title: 'Disable Map Options',
    description:
      'Disabling Map Services will remove the related aggregate and data. Any aggregate and UI customizations made for Map Services will be lost. This cannot be undone. Are you sure you want to disable this option?',
    confirmationText: 'Save',
  },
};

export const getPresentValue = (component: object, value: unknown, key: string) => {
  return hasIn(component, key) || value !== undefined ? { [key]: value } : {};
};

export const expandPath = (
  parts: string[],
  properties: JSONSchema6
): Array<{ [x: string]: JSONSchema6Definition | JSONSchema6Definition[] | undefined }> => {
  return parts.map((part, idx) => {
    let target = properties as JSONSchema6;
    let name = '';
    for (let i = 0; i < idx; i++) {
      target = get(target, parts[i]);
      name += parts[i].replace(/\[\d+\]/, '') + '.';
    }
    target = target ? get(target, part) : undefined;
    name += part.replace(/\[\d+\]/, '');
    if (target?.type === 'array') {
      return { [name]: target.items };
    }
    return { [name]: target };
  });
};
