import { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  Button,
  IconButton,
  makeStyles,
  Box,
  createStyles,
  Theme,
  useTheme,
} from '@material-ui/core';
import styled from 'styled-components';
import {
  mapScenarios,
  MapScenarioType,
  NodeMapDefinition,
} from '@terragotech/gen5-datamapping-lib';
import { EventMapDiagram, useDataMapDiagram } from '../../map-editor/src';
import CloseIcon from '@material-ui/icons/Close';
import { successMsg } from '../SnackbarUtilsConfigurator';
import { LocalSchemaDefinition, useSchemaLookup } from '../../utils/useSchemaLookup';
import { useFormDialog } from '../../components/FormDialog/FormDialogService';
import { MapperShortCutInfoDialog } from '../../components/FormDialog/MapperShortCutInfoDialog';
import { colors } from 'utils/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKeyboard, faSave } from '@fortawesome/pro-light-svg-icons';

export interface DataMapperDialogProps {
  open: boolean;
  onClose: () => void;
  datamap?: NodeMapDefinition | undefined;
  setDatamap: (newMap?: NodeMapDefinition) => void;
  mapScenario: keyof MapScenarioType;
  localSchemaDefinitions?: LocalSchemaDefinition;
  commandId?: string;
  aggregateType?: string;
}

function DataMapperDialog({
  open,
  datamap,
  setDatamap,
  onClose,
  mapScenario,
  localSchemaDefinitions,
  commandId,
  aggregateType,
}: DataMapperDialogProps) {
  return open ? (
    <DataMapperDialogWrapped
      open={open}
      datamap={datamap}
      setDatamap={setDatamap}
      onClose={onClose}
      mapScenario={mapScenario}
      localSchemaDefinitions={localSchemaDefinitions}
      aggregateType={aggregateType}
      commandId={commandId}
    />
  ) : (
    <></>
  );
}

function DataMapperDialogWrapped({
  open,
  datamap,
  setDatamap,
  onClose,
  mapScenario,
  localSchemaDefinitions,
  commandId,
  aggregateType,
}: DataMapperDialogProps) {
  const classes = useStyles();
  const [schema, setSchema] = useState(localSchemaDefinitions);
  const [dm, setDm] = useState(datamap);
  useEffect(() => {
    if (JSON.stringify(schema) !== JSON.stringify(localSchemaDefinitions)) {
      setSchema(localSchemaDefinitions);
    }
  }, [localSchemaDefinitions, schema]);
  const theme = useTheme();
  useEffect(() => {
    if (JSON.stringify(dm) !== JSON.stringify(datamap)) {
      setDm(datamap);
    }
  }, [datamap, dm]);

  const schemaLookup = useSchemaLookup({
    localSchemas: schema,
    commandId: commandId,
    currentAggregateType: aggregateType,
  });
  const { model, engine, hasSetPosition, setInitialDataMap } = useDataMapDiagram({
    dataMap: dm || (mapScenario ? mapScenarios[mapScenario].defaultNodeMap : undefined),
    schemaLookup: schemaLookup,
    scenario: mapScenario,
  });

  const handleSaveRequest = useCallback(() => {
    if (model?.getMapDefinition) {
      setDatamap(model.getMapDefinition() as NodeMapDefinition);
      successMsg('Data mapping successfully saved');
    }
    onClose();
    // eslint-disable-next-line
  }, [setDatamap, onClose, model]);

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const formDialog = useFormDialog();
  const handleShortCutInfo = () => {
    formDialog<typeof MapperShortCutInfoDialog>((props) => <MapperShortCutInfoDialog {...props} />);
  };
  const tStyles = { color: theme.palette.primary.main };
  return (
    <Dialog fullWidth maxWidth={false} open={open} onClose={handleCancel}>
      <EventMapperContainer>
        <IconButton onClick={onClose} className={classes.exitButton} title="Exit full-screen mode">
          <CloseIcon style={styles.closeIcon} />
        </IconButton>
        <Button
          color="primary"
          onClick={handleSaveRequest}
          style={{ ...styles.button, ...styles.saveBtn, ...tStyles }}
          startIcon={<FontAwesomeIcon icon={faSave} />}
        >
          Save
        </Button>
        <Button
          style={{ ...styles.button, ...styles.shortcutBtn }}
          onClick={handleShortCutInfo}
          startIcon={<FontAwesomeIcon icon={faKeyboard} />}
        >
          Shortcuts
        </Button>
        <EventMapDiagram
          engine={engine}
          model={model}
          mapScenario={mapScenario || 'BUTTON_STATE'}
          hasSetPosition={hasSetPosition}
          setInitialDataMap={setInitialDataMap}
        />
      </EventMapperContainer>
    </Dialog>
  );
}
const EventMapperContainer = styled(Box)`
  margin: 5px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  height: calc(100vh - 92px);
  width: calc(100% - 10px);
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    exitButton: {
      position: 'absolute',
      left: 10,
      top: 19,
      zIndex: 99,
      '&:hover': {
        backgroundColor: colors.black3,
      },
    },
  })
);
const styles = {
  button: {
    position: 'absolute' as const,
    backgroundColor: colors.white,
    boxShadow: `0px 2px 4px 0px ${colors.black10}`,
    zIndex: 10,
    height: 40,
    top: 31,
    fontSize: 16,
    fontWeight: 500,
  },
  saveBtn: {
    right: 20,
    width: 111,
  },
  shortcutBtn: {
    color: colors.black54,
    right: 141,
    width: 141,
  },
  closeIcon: { color: colors.grayDescription },
};
export default DataMapperDialog;
