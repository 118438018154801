import React, { useContext, useState } from 'react';
import {
  createStyles,
  Theme,
  makeStyles,
  Box,
  withStyles,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
  IconButton,
} from '@material-ui/core';
import { colors } from 'utils/colors';
import AddIcon from '@material-ui/icons/Add';
import { useNavigate, useParams } from 'react-router-dom';
import { useFunctionAPI } from 'context/fakeAPIHooks/useFunctionAPI';
import { ConfigContext } from 'context/ConfigContext';
import { successMsg, errorMsg } from 'components/SnackbarUtilsConfigurator';
import MuiListItemText from '@material-ui/core/ListItemText';
import { ADD_MODAL_WIDTH, CONFIRMATION, SHARED_ROUTE } from 'utils/Utils';
import { useFormDialog } from 'components/FormDialog/FormDialogService';
import { SingleTextInputForm } from 'components/FormDialog/SingleTextInputForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faMinus } from '@fortawesome/pro-solid-svg-icons';
import { truncateString } from 'utils/Utils';
import { useConfirmDialog } from 'context/ConfirmContext';
import { SearchBar } from 'views/components/SearchBar';

const BOTTOM_HEIGHT = 50;
const FunctionsList = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const formDialog = useFormDialog();
  const FunctionAPI = useFunctionAPI();
  const { getFunctions } = useContext(ConfigContext);
  const { aggregate: selected } = useParams() as { aggregate: string };
  const { openConfirmation } = useConfirmDialog();
  const [search, setSearch] = useState('');

  const handleAddFunction = async () => {
    const aggrName = await formDialog<typeof SingleTextInputForm>(
      (props) => <SingleTextInputForm title="Create new function" {...props} />,
      true,
      true,
      ADD_MODAL_WIDTH
    );
    if (!isValid(aggrName)) return;
    const { error } = await FunctionAPI.addFunction(aggrName);
    if (error) return;
    successMsg(`The function "${aggrName}" has been successfully added`);
  };
  const isValid = (aggrName: string) => {
    if (ifFunctionExists(aggrName)) {
      errorMsg(`A function named "${aggrName}" already exists`);
      return false;
    }
    return true;
  };

  const ifFunctionExists = (aggrName: string) => getFunctions()[aggrName];

  const handleAggrDelete = (aggrName: string) => async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.stopPropagation();
    const data = CONFIRMATION.functionList;
    const status = await openConfirmation(data);
    if (status === 'confirm') {
      if (aggrName === selected) navigate(SHARED_ROUTE);
      const { error } = await FunctionAPI.deleteFunction(aggrName);
      if (error) return;
      successMsg(`The function "${aggrName}" has been successfully deleted`);
    }
  };
  const ListItemText = withStyles({
    root: {
      '& .MuiTypography-displayBlock': {
        fontSize: '15px',
        fontStyle: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: '100%',
        display: 'inline',
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical',
      },
    },
  })(MuiListItemText);

  const handleAggregateClick = (name: string, e: React.MouseEvent) => {
    e.stopPropagation();
    const versionsCount = getFunctions()[name]?.versions?.length || 0;
    const maxVersion = versionsCount > 0 ? versionsCount - 1 : 0;
    const initialVersion = `version/${maxVersion}`;
    navigate(`${SHARED_ROUTE}/${name}/${initialVersion}/internalText`);
  };

  return (
    <Box className={classes.mainCont}>
      <ListItem divider className={classes.headerContainer}>
        <Typography className={classes.header}>Functions</Typography>
        <IconButton className={classes.listIcon}>
          <AddIcon onClick={() => handleAddFunction()} className={classes.addIcon} />
        </IconButton>
      </ListItem>
      <SearchBar value={search} onChange={setSearch} />
      <Box className={classes.listContainer}>
        <List>
          {Object.keys(getFunctions())
            .filter((name) => name.toLowerCase().includes(search.toLowerCase()))
            .sort((a, b) => (a.toLocaleLowerCase() > b.toLocaleLowerCase() ? 1 : -1))
            .map((name: string) => (
              <ListItem
                onClick={(e) => {
                  e.stopPropagation();
                  handleAggregateClick(name, e);
                }}
                className={`${classes.listItem} ${selected === name ? classes.selectedItem : ''}`}
              >
                <ListItemText
                  title={name}
                  primary={truncateString(name, 20)}
                  className={classes.listItemText}
                />
                <ListItemAvatar className={classes.listIcon}>
                  <span className={classes.removeIconContainer} onClick={handleAggrDelete(name)}>
                    <FontAwesomeIcon icon={faCircle} className={classes.circle} />
                    <FontAwesomeIcon icon={faMinus} className={classes.minus} />
                  </span>
                </ListItemAvatar>
              </ListItem>
            ))}
        </List>
      </Box>
    </Box>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainCont: {
      width: 300,
      backgroundColor: colors.white,
      borderRight: `1px solid ${colors.black10}`,
    },
    headerContainer: {
      width: '300px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0px 25px',
      paddingRight: 10,
    },
    listContainer: {
      padding: '0px 15px',
      paddingRight: 14,
      height: `calc(100% - ${BOTTOM_HEIGHT}px)`,
      overflowY: 'scroll',
      textOverflow: 'ellipsis',
    },
    header: {
      fontSize: 16,
      fontWeight: 500,
      padding: 0,
      color: colors.black,
    },
    selectedItem: {
      backgroundColor: theme.palette.primary.light,
      borderRadius: 5,
    },
    listItem: {
      width: 271,
      padding: 7,
      paddingLeft: 9,
      cursor: 'pointer',
      margin: '5px 0',
      height: 40,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    listItemText: {
      padding: 0,
      color: colors.black,
    },
    removeIconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    circle: {
      width: 25,
      height: 25,
      color: colors.lavenderBlush,
    },
    minus: {
      position: 'absolute',
      width: 14,
      color: colors.jellyBean,
    },
    listIcon: {
      textAlign: 'right',
      minWidth: '30px',
    },
    addIcon: {
      color: theme.palette.primary.main,
      display: 'flex',
    },
  })
);
export default FunctionsList;
