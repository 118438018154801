import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  Slider,
  Theme,
  Typography,
  alpha,
  makeStyles,
} from '@material-ui/core';
import { TextInputTG } from 'views/components/formElements';
import SymbolEditModal from './SymbolEditModal';
import { colors } from 'utils/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faPlus, faBars } from '@fortawesome/pro-regular-svg-icons';
import { getSvgImageString } from '@terragotech/svg-symbol-lib';
import { CONFIRMATION, SYMBOL_OPTION } from 'utils/Utils';
import { SymbolProps, useSymbolValue } from '../SymbolContext';
import { useConfirmDialog } from 'context/ConfirmContext';
import { SortableContainer, SortableElement, arrayMove, SortableHandle } from 'react-sortable-hoc';

interface Props {
  symbolLegend: SymbolProps[];
  handleAddSymbol: (value: object) => void;
  handleUpdateSymbol: (value: object, oldValue: object) => void;
  handleDeleteSymbol: (value: object) => void;
  symbolScale: number;
  setSymbolScale: Dispatch<SetStateAction<number>>;
  setSymbolLegend: Dispatch<SetStateAction<SymbolProps[]>>;
}
const DEFAULT_ICON_SIZE = 41;

const SymbolLegendView = ({
  symbolLegend,
  handleAddSymbol,
  handleUpdateSymbol,
  handleDeleteSymbol,
  symbolScale,
  setSymbolScale,
  setSymbolLegend,
}: Props) => {
  const classes = useStyles();
  const { setSelectedSymbol } = useSymbolValue();
  const [editorModal, setEditorModal] = useState(false);
  const { openConfirmation } = useConfirmDialog();
  const valuetext = useCallback((value: number) => `${value}%`, []);

  const handleInputChange = useCallback(
    (value: string) => {
      setSymbolScale(Number(value));
    },
    [setSymbolScale]
  );

  const handleSliderChange = useCallback(
    (_unused: React.ChangeEvent<{}>, newValue: number | number[]) => {
      setSymbolScale(newValue as number);
    },
    [setSymbolScale]
  );

  const handleModalOpen = (symbol: SymbolProps | null) => {
    setEditorModal(true);
    setSelectedSymbol(symbol);
  };

  const getSymbolStyle = () => {
    return {
      boxShadow: `0px 4px 4px ${colors.black25}`,
      borderRadius: (DEFAULT_ICON_SIZE * symbolScale) / 100,
      cursor: 'pointer',
      marginBottom: 6,
      height: (DEFAULT_ICON_SIZE * symbolScale) / 100,
      width: (DEFAULT_ICON_SIZE * symbolScale) / 100,
      background: colors.white,
    };
  };

  const onHandleDeleteSymbol = (symbol: SymbolProps) => async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.stopPropagation();
    const data = CONFIRMATION.symbolLegend({ name: symbol.name });
    const status = await openConfirmation(data);
    if (status === 'confirm') {
      handleDeleteSymbol(symbol);
    }
  };
  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
    const newSymbols = arrayMove(symbolLegend, oldIndex, newIndex);
    setSymbolLegend(newSymbols);
  };

  const DragHandle = SortableHandle(() => (
    <IconButton className={classes.dragButton} >
      <FontAwesomeIcon icon={faBars} className={classes.icon} />
    </IconButton>
  ));

  const SortableItem = SortableElement(({ key, symbol, handleModalOpen, onHandleDeleteSymbol }: {key: string, symbol: SymbolProps, handleModalOpen: any, onHandleDeleteSymbol: any }) => (
    <Box key={key} className={classes.symbol}>
      <Box className={classes.imageContainer} onClick={() => handleModalOpen(symbol)}>
        <DragHandle />
        <IconButton className={classes.deleteButton} onClick={onHandleDeleteSymbol(symbol)}>
          <FontAwesomeIcon icon={faClose} className={classes.icon} />
        </IconButton>
        <Box style={styles.symbolContainer}>
          <img
            src={getSvgImageString(symbol.symbolKey, SYMBOL_OPTION)}
            alt="symbol key"
            style={getSymbolStyle()}
          />
        </Box>
      </Box>
      <Typography className={classes.symbolDescription}>{symbol.name}</Typography>
    </Box>
  ));

const SortableList = SortableContainer(() => {
  return (
    <Box className={classes.symbols}>
      <Box className={classes.symbol}>
        <span style={styles.addIcon} onClick={() => handleModalOpen(null)}>
          <Box className={classes.circle}>
            <FontAwesomeIcon icon={faPlus} className={classes.plus} />
          </Box>
        </span>
        <Typography className={`${classes.symbolDescription} ${classes.add}`}>Add New</Typography>
      </Box>
      {symbolLegend.map((symbol, index) => (
        <SortableItem
          key={`${symbol.symbolKey}`}
          index={index}
          symbol={symbol}
          handleModalOpen={handleModalOpen}
          onHandleDeleteSymbol={onHandleDeleteSymbol}
        />
      ))}
    </Box>
  );
});

  return (
    <Box>
      <Box className={classes.scaleContainer}>
        <Box className={classes.scaleInnerContainer}>
          <Box>
            <Typography
              id="symbol-scale-slider"
              className={classes.sliderTitle}
              variant="subtitle1"
            >
              Scale map symbol size
            </Typography>
            <Typography className={classes.sliderDesc}>
              See symbols above for preview. Default size is 100%
            </Typography>
          </Box>
          <TextInputTG
            containerStyle={classes.outlined}
            value={symbolScale}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className={classes.percent}>
                  %
                </InputAdornment>
              ),
            }}
            onChange={handleInputChange}
            inputProps={outlinedInputProps}
          />
        </Box>
        <Slider
          max={200}
          min={1}
          value={symbolScale}
          onChange={handleSliderChange}
          aria-labelledby="symbol-scale-slider"
          getAriaValueText={valuetext}
          step={4}
          marks={marks}
          valueLabelDisplay="off"
          className={classes.slider}
        />
      </Box>
      <SortableList
        items={symbolLegend}
        onSortEnd={onSortEnd}
        axis="xy" 
        handleModalOpen={handleModalOpen}
        onHandleDeleteSymbol={onHandleDeleteSymbol}
        useDragHandle
      />
      <SymbolEditModal
        open={editorModal}
        setModal={setEditorModal}
        handleAddSymbol={handleAddSymbol}
        handleUpdateSymbol={handleUpdateSymbol}
        symbolLegend={symbolLegend}
      />
    </Box>
  );
};

const marks = [
  {
    value: 1,
    label: '1%',
  },
  {
    value: 100,
    label: '100%',
  },
  {
    value: 200,
    label: '200%',
  },
];

const outlinedInputProps = {
  min: 1,
  max: 200,
  type: 'number',
  'aria-labelledby': 'input-slider',
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    overflow: 'auto',
  },
  outlined: {
    width: '91px !important',
    height: 36,
    padding: '11px 0px 10px 0px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 9,
      background: colors.white,
    },
    '& input.MuiInputBase-input': {
      paddingRight: 0,
      paddingLeft: 11,
    },
  },
  titleIcon: {
    width: 24,
    height: 20,
  },
  symbols: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '37px 20px',
    marginTop: '30px',
  },
  symbol: {
    width: 118,
  },
  symbolDescription: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center',
    paddingTop: 8,
    lineHeight: '100%',
  },
  add: {
    color: theme.palette.primary.main,
  },
  scaleContainer: {
    borderRadius: 5,
    backgroundColor: colors.black1,
    padding: '19px 29px 16px 29px',
    marginTop: 12,
    position: 'relative',
  },
  scaleInnerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  slider: {
    marginTop: 25,
    padding: 0,
    '& .MuiSlider-markLabel': {
      top: 10,
      color: colors.black,
    },
  },
  circle: {
    width: 35,
    height: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px dashed ${theme.palette.primary.main}`,
    borderRadius: 17,
  },
  plus: {
    fontSize: 20,
    color: theme.palette.primary.main,
  },
  linkMenuHead: {
    width: 250,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    opacity: '1 !important',
  },
  linkMenuTitle: {
    color: colors.black,
    fontSize: '18px',
    fontWeight: 500,
  },
  linkMenuIcon: {
    width: 21,
    height: 14,
    padding: '4px',
    borderRadius: '15px',
    backgroundColor: colors.black5,
    color: colors.iconBlue,
  },
  imageContainer: {
    borderRadius: '15px',
    border: `1px solid ${colors.black10}`,
    backgroundColor: colors.lotion,
    position: 'relative',
    width: 118,
    height: 118,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  deleteButton: {
    position: 'absolute',
    top: -1,
    right: -1,
    cursor: 'pointer',
  },
  icon: {
    fontSize: 20,
    color: colors.black45,
  },
  sliderTitle: {
    fontSize: '16px',
    color: colors.black,
    fontWeight: 500,
    lineHeight: '100%',
    marginBottom: 8,
  },
  sliderDesc: {
    fontSize: '14px',
    color: colors.black,
    fontWeight: 400,
    lineHeight: '100%',
  },
  alertText: {
    color: colors.black,
  },
  menuItem: {
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundCOlor: alpha(theme.palette.primary.main, 0.04),
    },
  },
  activeMenuItem: {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
  },
  percent: {
    '& .MuiTypography-root': {
      color: colors.black,
    },
  },
  icons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dragButton: {
    position: 'absolute',
    top: -1,
    left: -1,
    cursor: 'pointer',
  },
}));

const styles: { [key: string]: React.CSSProperties } = {
  addIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '15px',
    border: `1px dashed ${colors.black10}`,
    width: 118,
    height: 118,
    cursor: 'pointer',
  },
  symbolContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60%',
  },
  deleteIcon: {
    marginLeft: '80%',
  },
};

export default SymbolLegendView;
